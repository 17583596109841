import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
import { css } from "@emotion/react";
import { auth } from "../../firebase.js";
import { useNavigate } from "react-router-dom";
import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import { BeatLoader } from "react-spinners";
import styles from "./ReactPlot.module.css";
import Footer from "../../Components/Footer/Footer.jsx";
import { useUser } from "../../UserContext.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

function Home() {
  const [data, setData] = useState({});
  const [xAxisStart, setXAxisStart] = useState(1);
  const [xAxisEnd, setXAxisEnd] = useState(100);
  const [selectedEngines, setSelectedEngines] = useState([0]);
  const [sensor, setSensor] = useState(1);
  const [chart, setChart] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRangeSubmit = () => {
    setChart(false);
    setLoading(true);
    axios
      .get(
        `https://us-central1-timeseries-552ab.cloudfunctions.net/api/api/data?start=${xAxisStart}&end=${xAxisEnd}&sensor=${sensor}&engines=${selectedEngines.join(
          ","
        )}`
      )
      .then((response) => {
        setLoading(false);
        setData(response.data);
        setChart(true);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    console.log("Received data:", data);
  }, [data]);

  const engineColors = [
    "rgba(0, 0, 255, 1)",
    "rgba(255, 0, 0, 1)",
    "rgba(0, 100, 0, 1)",
    "rgba(128, 0, 128, 1)",
    "rgba(255, 165, 0, 1)",
    "rgba(0, 255, 255, 1)",
    "rgba(255, 255, 0, 1)",
    "rgba(128, 128, 128, 1)",
  ];
  const chartData = {
    labels: data[selectedEngines[0]]?.map((item) => item.cycle) || [],
    datasets: selectedEngines.map((engineID, index) => ({
      label: `Device ${engineID}`,
      data: data[engineID]?.map((item) => item.value) || [],
      borderColor: engineColors[engineID % engineColors.length],
      borderWidth: 1,
      pointRadius: 0,
    })),
  };

  const sensorValue = ["none", "i_avg", , "kva_tot", "kvar_tot", "kw_tot"];

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          text: "Cycle",
        },
      },
      y: {
        type: "linear",
        title: {
          display: true,
          text: `${sensorValue[sensor]}`,
        },
      },
    },
  };
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const handleSignOut = (e) => {
    e.preventDefault();

    auth
      .signOut()
      .then(() => {
        setUser(null);
        navigate("/")
      })
      .catch((error) => {
        console.log("Sign out error:", error);
      });
  };
  const handleEngineChange = (engineID) => {
    const updatedEngines = selectedEngines.includes(engineID)
      ? selectedEngines.filter((id) => id !== engineID)
      : [...selectedEngines, engineID];
    setSelectedEngines(updatedEngines);
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <>
      <div className={styles.navbar}>
        Engines
        <button onClick={handleSignOut}>Logout</button>
      </div>
      <div className={styles.container}>
        <div className={styles.inputContainer}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label htmlFor="startNumber" style={{ width: "120px" }}>
              Start Cycle:
            </label>
            <input
              type="number"
              id="startNumber"
              value={xAxisStart}
              onChange={(e) => setXAxisStart(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label htmlFor="endNumber" style={{ width: "120px" }}>
              End Cycle:
            </label>
            <input
              type="number"
              id="endNumber"
              value={xAxisEnd}
              onChange={(e) => setXAxisEnd(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label htmlFor="sensor" style={{ width: "120px" }}>
              Sensor:
            </label>
            <select
              id="sensor"
              value={sensor}
              onChange={(e) => setSensor(e.target.value)}
              style={{ marginLeft: "25px" }}
            >
              <option value="1">i_avg</option>
              <option value="2">kva_tot</option>
              <option value="3">kvar_tot</option>
              <option value="4">kw_tot</option>
            </select>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label htmlFor="devices" style={{ width: "120px" }}>
              Select Devices:
            </label>
            {[0, 1, 2, 3, 4, 5, 6, 7].map((engineID) => (
              <label
                style={{ marginLeft: "10px", fontWeight: "initial" }}
                key={engineID}
              >
                Device {engineID}
                <input
                  type="checkbox"
                  value={engineID}
                  checked={selectedEngines.includes(engineID)}
                  onChange={() => handleEngineChange(engineID)}
                />
              </label>
            ))}
          </div>
          <button className={styles.submitButton} onClick={handleRangeSubmit}>
            Submit
          </button>
        </div>
        {loading && (
          <div className={styles.chartContainer}>
            <BeatLoader
              css={override}
              size={15}
              color={"#123abc"}
              loading={loading}
            />
          </div>
        )}
        {chart && (
          <div className={styles.chartContainer}>
            <Line
              data={chartData}
              options={chartOptions}
              className={styles.chartCanvas}
            />
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Home;
